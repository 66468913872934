import React,{useEffect} from 'react'
import './style.scss'
import CardPremiacao from '../../Components/CardPremiacao'
import BgMobile from '../../img/bg-mobile-reward.svg'
import nexaSipatLogo from '../../img/nexa-logo-white.png'
import nexaSipatRewards from '../../img/premios-sipat-nexa.png'
import scrollbutton from '../../img/scrollbutton.svg'
// import caixajbl from '../../img/caixajbl.png'
// import jblheadphone from '../../img/jblheadphone.png'
import jblfone from '../../img/jblfone.png'
// import jblfone from '../../img'
import matheadphone from '../../img/matheadphone.png'
import powerbanck from '../../img/powerbanck.png'
import mbfooter from '../../img/mobile-footer.svg'
import BtnImg from '../../img/button-sipat-nexa.svg'
import appexibition from '../../img/appexibition.png'
import android from '../../img/android.svg'
import termica from '../../img/termica.png'
import garrafaTermica from '../../img/garrafaTérmica.png'
import jbl from '../../img/jbl.png'
import ios from '../../img/ios.svg'
import ChangeLanguage from '../../Components/ChangeLanguage'
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next'
import pt_BR from '../../translations/pt_BR.json'
import en_US from '../../translations/en_US.json'
import es_ES from '../../translations/es_ES.json'
import DownloadBtnLeft from '../../Components/DownloadBtnLeft';
import { BrowserView, MobileView } from "react-device-detect";
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const Premiacao = props => {
    const { t } = useTranslation()
    const { language, setlanguage } = props


    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);

    const scrollPage = () => {
        window.scrollBy({ top: +(window.innerHeight + 100), behavior: "smooth" });
    }

   const handleClick = () => {
        window.open('https://sipatnexa.page.link/download', '_blank')
    }
    return (
        <div className="reward" >
            <header className="reward-header" style={{ backgroundImage: `url(${BgMobile})` }}>
                <div className="reward-headercontent">
                    <img src={nexaSipatLogo} className="reward-nexaSipatLogo" alt="Logo Nexa" />
                    <img src={nexaSipatRewards} className="reward-nexaSipatRewards" alt="Rewards" />
                    <h1 className="reward-title">{t('RewardsH1')}</h1>
                    <p className="reward-p1">{t('RewardsP1')}</p>
                    <p className="reward-p2">{t('RewardsP2')}</p>
                    <ChangeLanguage language={language} setLanguage={setlanguage}/>
                    <div className="reward-scrollBtn">
                        <p className="reward-scrollText">{t('scrollBtnText')}</p>
                        <img src={scrollbutton} className="reward-scrollBtn" onClick={scrollPage} alt="scroll" />
                    </div>
                </div>
                    <img src={nexaSipatRewards} className="reward-nexaSipatRewardsout" alt="Rewards" />
            </header>
            <main className="reward-rewardsarea">
                <CardPremiacao img={jbl} rank={t('1positionTItle')} reward={t('reward1')} text={t('rewardCardP')} />
                <CardPremiacao img={jblfone} rank={t('2positionTItle')} reward={t('reward2')}  text={t('rewardCardP')} />
                <CardPremiacao img={powerbanck} rank={t('3positionTItle')} reward={t('reward3')}  text={t('rewardCardP')} />
                <CardPremiacao img={termica} rank={t('4positionTItle')} reward={t('reward4')} text={t('rewardCardP')} />
                <CardPremiacao img={matheadphone} rank={t('5positionTItle')} reward={t('reward5')}  text={t('rewardCardP')} />
                <CardPremiacao img={garrafaTermica} rank={t('6to10positionTItle')} reward={t('reward6to10')}  text={t('rewardCardP')} />
            </main>
             <main className="reward-rewardsarea">
            </main>
            <div className="reward-date">
                <p className="reward-dateinfo">{t('resultDayP1')}<br />{t('resultDayP2')}</p>
            </div>
            <footer className="reward-footer" style={{ backgroundImage: `url(${mbfooter})` }}>
                <div className="reward-footerinfo">
                    <p className="reward-footerdowninfo">{t('downloadP1')}<br />{t('downloadP2')}</p>
                    <MobileView className="reward-downBtnArea">
                    <button className="reward-downBtn" onClick={handleClick}>
                        <h6>{t('btnDownload')}</h6>
                        <div className="reward-btnimgWrapper">
                            <img src={BtnImg} alt="" />
                        </div>
                    </button> 
                    </MobileView>  
                    <BrowserView className = "reward-btnsDownload">
                            <DownloadBtnLeft typeBtn={'android'} />                            
                                               
                            <DownloadBtnLeft typeBtn={'ios'} />
                        </BrowserView>         
                        <MobileView>          
                    <div className="reward-storeiconsarea">
                        <div className="reward-storeicons">
                            <img src={ios} alt="ios icon" className="reward-storeicon" />
                            <img src={android} alt="android icon" className="reward-storeicon" />
                        </div>
                        <p className="reward-iconstext">{t('plataforms')}</p>
                    </div>
                    </MobileView>    
                </div>
                <img className="reward-appexibition" src={appexibition} alt="" />
            </footer>
        </div>
    );
};


Premiacao.propTypes = {
    language: PropTypes.string.isRequired,
    setlanguage: PropTypes.func.isRequired
};
export default Premiacao;