import './App.css';
import React, { useEffect, useState } from 'react'
import Reward from './Pages/Reward'
import Home from './Pages/Home'
import Winners from './Pages/Winners'
import { getDateApi } from './Services/apiSearch'
import LoadInitial from './Components/LoadInitial';
import sipatContext from './Context/Context';

function App() {
  const {
    eventdate,
    setEventdate,
    rewarddate,
    setRewarddate,
    allowRegistrationOutRange,
    setAllowRegistrationOutRange,
    todaydate,
    setTodaydate,
  } = React.useContext(sipatContext),
  // const [todaydate, setTodaydate] = useState(1),
    // [eventdate, setEventdate] = useState(2),
    // [rewarddate, setRewarddate] = useState(3),
    // [allowRegistrationOutRange, setAllowRegistrationOutRange] = useState(),
    home = process.env.REACT_APP_SIPAT_API,
    [language, setlanguage] = useState('br')

  useEffect(() => {
    const getDefaultLanguage = () => {
      switch (navigator.language) {
        case 'en-US':
          setlanguage('en')
          break
        case 'es':
          setlanguage('es')
          break
        default:
          setlanguage('br')
      }
    }
    getDefaultLanguage()
    getDateApi(home, setTodaydate, setEventdate, setRewarddate, setAllowRegistrationOutRange)
  }, [])

  // useEffect(() => {  
  //   getDateApi(home,setTodaydate,setEventdate,setRewarddate)  
  // },[todaydate,eventdate,rewarddate]);

  const handleScreens = () => {
    if (todaydate !== 1) {     
      if (allowRegistrationOutRange === "true" || allowRegistrationOutRange === true) return <Home />    
      else {
        if (todaydate < eventdate && allowRegistrationOutRange === "true" || allowRegistrationOutRange === true) {
          return <Home />
        }
        if ((todaydate <= rewarddate) && allowRegistrationOutRange === "false" || allowRegistrationOutRange === false) {
          return <Reward language={language} setlanguage={setlanguage} />
        }
        else {
          return <Winners />
        }
      }
    }
    else { return <LoadInitial /> }
  
  }

  return (
    <div className="App">
      {handleScreens()}
    </div>
  );
}

export default App;