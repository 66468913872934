import React from 'react';
import './style.scss';
import WinnersCard from '../WinnersCard';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const WinnersRight = props => {
    const { rank, unity, unities } = props
    const { t } = useTranslation()
    return (
        <div className='winnersRight'>
            <p className="winnersRight-headP">{t('anotherWinners')}</p>
            <div className="winnersRight-cardsArea">
                {rank && rank.slice(3, 11).map((option, index) => (
                    <WinnersCard key={index} 
                    postionRank={index + 4} 
                    nameRank={option.nickname}
                     unityRank={unities[unity - 1] && unities[unity - 1].name} 
                     coinsRank={option.coins} avatarid={option.avatarid} />
                ))}

            </div>
        </div>
    );
}


WinnersRight.propTypes = {
    rank: PropTypes.array,
    unity: PropTypes.number,
    unities: PropTypes.array
}.isRequired;

export default WinnersRight;
