import React from 'react';
import './style.scss';
import trophy1 from '../../img/trophy1.png';
import trophy2 from '../../img/trophy2.png';
import trophy3 from '../../img/trophy3.png';
import bgWinners from '../../img/bgWinners.png';
import zincoins from '../../img/zincoins.svg';
import caixajbl from '../../img/caixajbl.png';
import jblheadphone from '../../img/jblheadphone.png';
import jblfone from '../../img/jblfone.png';
import locationIcon from '../../img/locationIcon.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });


const WinnersPodiumCard = props => {
    const { postionRank, nameRank, unityRank, coinsRank,avatarid } = props
    const { t } = useTranslation()
    const returnTrophy = () => {
        switch (postionRank) {
            case 1:
                return <img className="winnersPodiumCard-imgTrophy" src={trophy1} alt="Trophy Image" />
            case 2:
                return <img className="winnersPodiumCard-imgTrophy" src={trophy2} alt="Trophy Image" />
            case 3:
                return <img className="winnersPodiumCard-imgTrophy" src={trophy3} alt="Trophy Image" />
        }
    }

    const returnReward = () => {
        switch (postionRank) {
            case 1:
                return <>
                    <img src={caixajbl} className="winnersPodiumCard-rewardImg" />
                    <h4 className="winnersPodiumCard-rewardTitle">{t('1rewardP')}</h4>
                </>

            case 2:
                return <>
                    <img src={jblheadphone} className="winnersPodiumCard-rewardImg" />
                    <h4 className="winnersPodiumCard-rewardTitle">{t('2rewardP')}</h4>
                </>

            case 3:
                return <>
                    <img src={jblfone} className="winnersPodiumCard-rewardImg" />
                    <h4 className="winnersPodiumCard-rewardTitle">{t('3rewardP')}</h4>
                </>

        }
    }

    return (
        <div className='winnersPodiumCard' style={{ backgroundImage: `url(${bgWinners})` }}>
            <div className="winnersPodiumCard-rank">
                <h3 className="winnersPodiumCard-positionTitle">{`#${postionRank}`}</h3>
                <div className="winnersPodiumCard-imgWrapperTrophy">
                    {returnTrophy()}
                </div>
            </div>
            <div className="winnersPodiumCard-userData">
                <div className="winnersPodiumCard-imgWrapperAvatar">
                    <img src={`https://cdn.sipatnexa.com/images/avatars/${avatarid}.png`} alt="Avatar Image" className="winnersPodiumCard-imgAvatar" />
                </div>
                <div className="winnersPodiumCard-userDataName">
                    <h2 className="winnersPodiumCard-userName">{nameRank}</h2>
                    <div className="winnersPodiumCard-unityCoinsArea">
                        <div className="winnersPodiumCard-unityArea">
                            <img src={locationIcon} className="winnersPodiumCard-unityCoinsAreaIcon" />
                            <h2 className="winnersPodiumCard-unityTitle">{unityRank}</h2>
                        </div>
                        <div className="winnersPodiumCard-CoinsArea">
                            <img src={zincoins} className="winnersPodiumCard-unityCoinsAreaIcon" />
                            <h2 className="winnersPodiumCard-unityTitle">{coinsRank}</h2>
                        </div>
                    </div>
                </div>


            </div>
            <div className="winnersPodiumCard-reward">
                {returnReward()}


            </div>
        </div>
    );
}


WinnersPodiumCard.propTypes = {
    postionRank: PropTypes.number,
    nameRank: PropTypes.string,
    unityRank: PropTypes.string,
    coinsRank: PropTypes.number,
    avatarid: PropTypes.string
}

export default WinnersPodiumCard;