import React from 'react'
import './style.scss'
import Lottie from "react-lottie";
import capacete from "../../img/capacete.json";

const LoadInitial = () => {
    const loadDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: capacete,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div className='loadInitial'>
            <Lottie options={loadDefaultOptions} height={'40vh'} width={'40vh'} />
        </div>
    );
}

export default LoadInitial