import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MyContext from './Context';

function Provider({ children }) {
   const [todaydate, setTodaydate] = useState(1);
   const [eventdate, setEventdate] = useState(2);
   const [rewarddate, setRewarddate] = useState(3);
   const [allowRegistrationOutRange, setAllowRegistrationOutRange] = useState();

    const state = {
        setTodaydate,
        todaydate,
        setEventdate,
        eventdate,
        setRewarddate,
        rewarddate,
        setAllowRegistrationOutRange,
        allowRegistrationOutRange
  };

  return (
    <MyContext.Provider value={ state }>
      {children}
    </MyContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;