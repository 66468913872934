import React, { useState, useEffect } from 'react';
import './style.css';
import WinnersLeft from '../../Components/WinnersLeft';
import WinnersRight from '../../Components/WinnersRight';

const Winners = () => {
  const [unity, setUnity] = useState(1),
    [unities, setUnities] = useState([]),
    [rank, setRank] = useState([]),
    [language, setLanguage] = useState('br'),
    home = process.env.REACT_APP_SIPAT_API;

  useEffect(() => {
    const getDefaultLanguage = () => {
      switch (navigator.language) {
        case 'en-US':
          setLanguage('en');
          break;
        case 'es':
          setLanguage('es');
          break;
        default:
          setLanguage('br');
      }
    };
    getDefaultLanguage();
    const getDefaultUnityData = async () => {
      const response = await fetch(`${home}/dashboard/ranking?unit=${1}`),
        data = await response.json();

      setRank(data.data.slice(0, 10));
    };
    getDefaultUnityData();
    const getUnity = async () => {
      const response = await fetch(`${home}/groups?type=unit`),
        data = await response.json();
      setUnities(data.data);
    };
    getUnity();
  }, []);
  return (
    <div className="winners">
      <WinnersLeft
        unity={unity}
        setUnity={setUnity}
        unities={unities}
        rank={rank}
        setRank={setRank}
        language={language}
        setLanguage={setLanguage}
      />
      <WinnersRight unity={unity} rank={rank} unities={unities} />
    </div>
  );
};

export default Winners;
