import React from 'react';
import BtnImg from '../../../img/button-sipat-nexa.svg'
import './style.scss'
import PropTypes from 'prop-types';

const FormBtn = props => {
    const handleClick = ()=>{       
       window.open('https://sipatnexa.page.link/download', '_blank');
    }
    return (        
        <button className="formBtn" onClick={handleClick}>           
                <h6>{props.text}</h6>
                <div className="formBtn-imgWrapper">
                    <img src={BtnImg} alt="" />
                </div>     
        </button>
    );
};

FormBtn.propTypes = {
    text: PropTypes.string.isRequired,
};

export default FormBtn;