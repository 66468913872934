import React from 'react';
import './style.scss';
import locationIcon from '../../img/locationIconGrey.svg';
import zincoinsGrey from '../../img/zincoinsGrey.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const WinnersCard = props => {
    const {nameRank,postionRank,coinsRank,unityRank,avatarid}=props
    const { t } = useTranslation()
    const returnRewardP = ()=>{
        if (postionRank===4) return t('4rewardP')
        if (postionRank===5) return t('5rewardP')
        if (postionRank>5) return t('6rewardP')
    }
    return (
        <div className='winnersCard'>
            <div className="winnersCard-left">
                <h4 className="winnersCard-position">{`#${postionRank}`}</h4>
                <div className="winnersCard-profileImgWrapper">
                <img src={`https://cdn.sipatnexa.com/images/avatars/${avatarid}.png`} alt="Avatar Image" className="winnersCard-imgAvatar" />
                </div>
            </div>
            <div className="winnersCard-Right">
                <h5 className="winnersCard-name">{nameRank}</h5>
                <div className="winnersCard-coinsUnityArea">
                    <div className="winnersCard-unityArea">
                        <img src={locationIcon} alt="Icon" className="unityIcon" />
                        <p className="winnersCard-unity">{unityRank}</p>
                    </div>
                    <div className="winnersCard-coinsArea">
                        <img src={zincoinsGrey} alt="Icon" className="coinIcon" />
                        <p className="winnersCard-coins">{coinsRank}</p>
                    </div>
                </div>
                <p className="winnersCard-reward">{returnRewardP()}</p>
            </div>
        </div>
    );
}

WinnersCard.propTypes = {
    nameRank: PropTypes.string,
    postionRank: PropTypes.number,
    coinsRank: PropTypes.number,
    unityRank: PropTypes.string,
    avatarid: PropTypes.string
}

export default WinnersCard;