import React from 'react';
import './style.scss'
import PropTypes from 'prop-types';

const CardPremiacao = props => {
    const {img,rank,reward,text} = props
    return (
        <div className="CardPremiacao">
            <div className="CardPremiacao-body">
                <div className="CardPremiacao-imgwrapper">
                    <img src={img} alt="" />
                </div>
                <h2 className="CardPremiacao-rank">{rank}</h2>
                <h3 className="CardPremiacao-reward">{reward}</h3>
            </div>
            <p>{text}</p>
        </div>
    );
};

CardPremiacao.propTypes = {
    img: PropTypes.string,
    rank: PropTypes.string,
    reward: PropTypes.string,
    text: PropTypes.string
}
export default CardPremiacao;