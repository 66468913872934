const getSettingsValue = (elem, name) => {
  return elem.find((e) => e.name === name ).value;
}

export const getDateApi = async (
  home,
  setTodaydate,
  setEventdate,
  setRewarddate,
  setAllowRegistrationOutRange
) => {
  const allDates = await fetch(
      `${home}/game/settings?name=timeNow,sipat.startDate,sipat.endDate,allowRegistrationOutRange,sipat.dateResult`
    ),
    // eslint-disable-next-line no-unused-vars
  allDatesRes = await allDates.json();
  setAllowRegistrationOutRange(getSettingsValue(allDatesRes.data, 'sipat.allowRegistrationOutRange'));
  setTodaydate(allDatesRes.data[0]);
  setEventdate(getSettingsValue(allDatesRes.data, 'sipat.startDate'));
  setRewarddate(getSettingsValue(allDatesRes.data, 'sipat.dateResult'));
};

