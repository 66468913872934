import React, { useEffect } from 'react';
import './style.scss';
import FormBtn from '../Form/FormBtn';
import nexaLogo from '../../img/logo-sipat-nexa-orange.png';
import pronto from '../../img/PRONTO.png';
import done from '../../img/DONE.png';
import listo from '../../img/LISTO.png';
import iosIc from '../../img/android.svg';
import androidIc from '../../img/ios.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import { BrowserView, MobileView } from "react-device-detect";
import DownloadBtnLeft from '../DownloadBtnLeft';
import PropTypes from 'prop-types';


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            },
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const FinishScreen = props => {
    const {language} = props
    const { t } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);

    const returnFinishImg=()=>{
        switch (language) {
            case 'br':
                return pronto
            case 'en':
                return done
            case 'es':
                return listo
          
        }
    }

    return (
        <div className="finishScreen">
            <img className="finishScreen-imgNexaLogo" src={nexaLogo} alt="" />
            <div className="finishScreen-prontoWrapper">
                <img className="finishScreen-imgPronto" src={returnFinishImg()} alt="" />
            </div>
            <p className="finishScreen-downP">{t("DownloadNowpt1")}<br />{t("DownloadNowpt2")}</p>
            <MobileView className="finishScreen-BtnArea">
                <FormBtn text={t("DownloadNow")} />
            </MobileView>


            <div className="finishScreen-iconWrapper">
                <MobileView className="finishScreen-iconArea">
                    <img src={iosIc} alt="" className="finishScreen-imgIcon" />
                    <img src={androidIc} alt="" className="finishScreen-imgIcon" />
                </MobileView>  
                <BrowserView className="finishScreen-DownloadArea">
                <DownloadBtnLeft typeBtn={'android'} language={language} />   
                <DownloadBtnLeft typeBtn={'ios'} language={language}/>
                </BrowserView>

            </div>
            <MobileView>
                <p className="finishScreen-DispP">{t("Disponível para Android e IOS")}</p>
            </MobileView>
        </div>
    );
};

FinishScreen.propTypes = {
    language: PropTypes.string
}

export default FinishScreen;