import React,{useEffect} from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';


//flags
import Brasil from '../../img/brazil-flag.png'
import Peru from '../../img/peru-flag.png'
import Eua from '../../img/usa-flag.png'

const Control = styled(FormControl)`
  margin: 2rem 0 !important;
  @media only screen and (max-width: 540px) {
    margin: 2rem 0 1rem 0 !important;
  }
`;

const TheSelect = styled(Select)`
  color: #FF6505 !important;
  &::before {
    border-color: #FF6505 !important;
  }
  &::after {
    border-color: #FF6505 !important;
    color: #FF6505;
  }
  svg {
    color: #FF6505;
  }
  .MuiInput-input,
  .MuiMenuItem-root {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .MuiSelect-select:focus:focus{
    background-color: transparent !important;
  }
`;



i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            },
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const ChangeLanguage = props => {
 
  const { language,setLanguage } = props,
   { t } = useTranslation()

  useEffect(() => {
      i18n.changeLanguage(language)
  }, [language]);

 
  


  const handleChange = (event) => {
    setLanguage(event.target.value)
  }
  
 
  return (
    <Control>
      <TheSelect
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        value={language}
      >
        <MenuItem value={"br"} >
          <img
            style={{
              maxHeight: "1.5rem",
              marginRight: "1rem",
            }}
            src={Brasil}
            alt="BR"
          />
        {t('Portuguese')}
        </MenuItem>
        <MenuItem value={"en"}>
          <img
            style={{
              maxHeight: "1.5rem",
              marginRight: "1rem",
            }}
            src={Eua}
            alt="EN"
          /> 
          {t('English')}
        </MenuItem>
        <MenuItem value={"es"}>
          <img
            style={{
              maxHeight: "1.5rem",
              marginRight: "1rem",
            }}
            src={Peru}
            alt="ES"
          />
          {t('Spanish')}
        </MenuItem>
      </TheSelect>
    </Control>
  );
};


ChangeLanguage.propTypes = {
  language: PropTypes.string,
  setLanguage: PropTypes.func
}

export default ChangeLanguage;