import React,{useEffect, useState} from 'react';
import './style.scss'
import BannerLeft from '../../Components/BannerLeft/index';
import Form from '../../Components/Form/index';
import FinishScreen from '../../Components/FinishScreen';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';


const Home = () => {      
    const [isFinished,setisFinished]=useState(false),
     [language,setlanguage]=useState('br'),
     siteKey = process.env.REACT_APP_RECAPTCHA_KEY

    useEffect(() => {  
        const getDefaultLanguage = () => {        
            switch (navigator.language) {
                case 'en-US':
                    setlanguage('en')
                    break                         
                case 'es':
                    setlanguage('es')
                    break
                default:
                    setlanguage('br')                   
            }           
        }
        getDefaultLanguage()      
      },[])  
    
    return (
        <div className='home'>
             <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
           <BannerLeft language={language} setlanguage={setlanguage}/>
           {isFinished?<FinishScreen  language={language} setlanguage={setlanguage}/>:<Form  isFinished={isFinished} setisFinished={setisFinished} language={language}/>}  
           </GoogleReCaptchaProvider>                            
        </div>
    );
};

export default Home;