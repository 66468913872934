import React from 'react';
import './style.scss';
import androidIcon from '../../img/androidWhite.svg';
import iosIcon from '../../img/iosWhite.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            },
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const DownloadBtnLeft = props => {
    const { typeBtn } = props,
     { t } = useTranslation(),
    LinkAndroidIOSDynamic='https://sipatnexa.page.link/download',
    LinkIOS='https://apps.apple.com/us/app/sipat-game/id1578939162',
    LinkAndroid='https://play.google.com/store/apps/details?id=com.sipatnexa.sipatgame',

        handleClick = () => {
            typeBtn === 'dynamic'&& window.open(LinkAndroidIOSDynamic, '_blank')
            typeBtn === 'ios'&& window.open(LinkIOS, '_blank')
            typeBtn === 'android'&& window.open(LinkAndroid, '_blank')           
        },

        returnBtn = () => {
            if (typeBtn === 'dynamic') {
                return <>
                    <h1 className='downloadBtnLeft-text'>{t('btnDownload')}</h1>
                    <img src={iosIcon} alt="" className="downloadBtnLeft-icon" />
                    <img src={androidIcon} alt="" className="downloadBtnLeft-icon" />
                </>
            } if (typeBtn === 'ios') {
                return <>
                    <h1 className='downloadBtnLeft-text'>{t('btnDownload')}<br/>{t('IOSDownloadBtn')}</h1>
                    <img src={iosIcon} alt="" className="downloadBtnLeft-icon" />
                </>
            } else {
                return <>
                    <h1 className='downloadBtnLeft-text'>{t('btnDownload')}<br/>{t('androidDownloadBtn')}</h1>
                    <img src={androidIcon} alt="" className="downloadBtnLeft-icon" />
                </>
            }

        }

    return (
        <button className={`downloadBtnLeft_${typeBtn==='dynamic'?'desktop':'mobile'}`} onClick={handleClick}>
            {returnBtn()}
        </button>
    );
};


DownloadBtnLeft.propTypes = {
    typeBtn: PropTypes.string.isRequired
}

export default DownloadBtnLeft;