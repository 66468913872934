import React, { useState, useEffect } from "react";
import './style.scss'
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    InputAdornment,
    IconButton,
    MenuItem
} from '@material-ui/core'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PasswordChecklist from 'react-password-checklist'
import BtnImg from '../../img/button-sipat-nexa.svg'
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next'
import pt_BR from '../../translations/pt_BR.json'
import en_US from '../../translations/en_US.json'
import es_ES from '../../translations/es_ES.json'
import Lottie from "react-lottie";
import capacete from "../../img/capacete.json";
import PropTypes from 'prop-types';
import ModalError from "../ModalError";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],
        interpolation: {
            escapeValue: false
        }
    });

    const Form = props => {
    const { setisFinished } = props,
    { t } = useTranslation(),
        [nameForm, setnameForm] = useState(''),
        [nameFormError, setnameFormError] = useState(false),
        [emailForm, setemailForm] = useState(''),
        [emailFormError, setemailFormError] = useState(false),
        [emailFormErrorText, setemailFormErrorText] = useState(t('emailCadastrado')),
        [countryFormError, setcountryFormError] = useState(false),
        [stateFormError, setstateFormError] = useState(false),
        [cityFormError, setcityFormError] = useState(false),
        [passwordForm, setpasswordForm] = useState(''),
        [passwordFormError, setpasswordFormError] = useState(false),
        [showPasswordErrorArea, setshowPasswordErrorArea] = useState(false),
        [passwordFormAgain, setpasswordFormAgain] = useState(''),
        [phoneForm, setphoneForm] = useState(''),
        [phoneFormError, setphoneFormError] = useState(false),
        [termsForm, settermsForm] = useState(false),
        [termsFormEror, settermsFormEror] = useState(false),
        [passwordhidden, setpasswordhidden] = useState(true),
        [iscollaborator, setIscollaborator] = useState('true'),
        [country, setcountry] = useState(''),
        [unity, setUnity] = useState(''),
        [unityError, setUnityError] = useState(false),
        [unities, setUnities] = useState([]),
        [showModal, setShowModal] = useState(false),
        [modalErrorText, setModalErrorText] = useState(2),      
        { language } = props,
        home = process.env.REACT_APP_SIPAT_API,
        { executeRecaptcha } = useGoogleReCaptcha(),
        [loadHidden, setLoadHidden] = useState(true),
        validateEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        loadDefaultOptions = {
            loop: true,
            autoplay: true,
            animationData: capacete,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        }
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);


    const getSta = async (country) => {
        switch (country) {
            case 'USA':
                setStates(["Texas"])
                setstate("Texas")
                setcities(["Houston"])
                setcity("Houston")
                setstateFormError(false)
                setcityFormError(false)
                break;
            case 'Luxemburgo':
                setStates(['Diekirch'])
                setstate('Diekirch')
                setcities(['Ettelbruck'])
                setcity('Ettelbruck')
                setstateFormError(false)
                setcityFormError(false)
                break;

            default:
                // eslint-disable-next-line no-case-declarations
                const responsebr = await fetch(`${home}/register/city?country=${country}`),
                    data = await responsebr.json()
                setStates(data.data)
                setcities([])
                setstate('')
                setcity('')
                break;
        }
    }

    const getCit = async (stateprop) => {
        setstate(stateprop)
        if (country !== 'USA' && country !== 'Luxemburgo') {
            const response = await fetch(`${home}/register/city?country=${country}&state=${stateprop}`),
                data = await response.json()
            data.data && setcities(data.data)
        }
    }
    const getUnity = async () => {
        const response = await fetch(`${home}/groups?type=unit`),
            data = await response.json()
        setUnities(data.data);
    }
    useEffect(() => {
        getUnity()
    }, []);
    const checkEmailApi = async (email,where) => {
        const response = await fetch(`${home}/users/check-email?email=${email}`),
            data = await response.json()     
        if (data.status === 'Bad request') {
            if(where==='form'){
                setemailFormErrorText(t('emailCadastrado'))
                setemailFormError(true)               
            }else{
                setModalErrorText(1)
                setShowModal(true)                
            }
        }else{
            setemailFormError(false)
        }
    }
    const radioEvent = e => {
        setIscollaborator(e.target.value)
        setUnity('')
    }

    const handleChangeName = (event) => {
        setnameForm(event.target.value)
        event.target.value !== '' && setnameFormError(false)
    }
    const handleChangeEmail = (event) => {
        if(  validateEmail.test(event.target.value) ){
            checkEmailApi(event.target.value,'form')
        }else{
            setemailFormErrorText(t('emailInputHelper'))
            setemailFormError(true)
        }
    
        setemailForm(event.target.value)
        //event.target.value !== '' && setemailFormError(false)
    }
    const handleChangePhone = (event) => {
        setphoneForm(event)
        event !== '' && setphoneFormError(false)
    }

    //-----Country
    const countries = [
        { value: 'Brasil', label: 'Brasil', },
        { value: 'USA', label: 'USA', },
        { value: 'Peru', label: 'Peru', },
        { value: 'Luxemburgo', label: 'Luxemburgo', },
    ];
    const handleChangeCountry = (event) => {
        setcountry(event.target.value)
        getSta(event.target.value)
        event.target.value !== '' && setcountryFormError(false)
        state !== '' && setstateFormError(false)
        city !== '' && setcityFormError(false)
    }
    //-----State
    const [states, setStates] = useState([])
    const [state, setstate] = useState('');
    const handleChangeState = (event) => {
        if (event.target.value !== '') {
            getCit(event.target.value)
            setstate(event.target.value)
            setstateFormError(false)
        }
    }
    //-----City
    const [cities, setcities] = useState([])
    const [city, setcity] = useState('');
    const handleChangeCity = (event) => {
        if (event.target.value !== '') {
            setcity(event.target.value)
            setcityFormError(false)
        }
    }
    //-----  
    const handleChangeUnity = (event) => {
        setUnity(event.target.value)
        event.target.value !== '' && setUnityError(false)
    }
    const handleTermsChange = () => {
        settermsForm(!termsForm)
        termsForm === false && settermsFormEror(termsForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()     
        country === '' ? setcountryFormError(true) : setcountryFormError(false)
        state === '' ? setstateFormError(true) : setstateFormError(false)
        city === '' ? setcityFormError(true) : setcityFormError(false)
        nameForm === '' ? setnameFormError(true) : setnameFormError(false)
        if ( !validateEmail.test(emailForm)){
            setemailFormErrorText(t('emailInputHelper'))
            setemailFormError(true)
        }
    console.warn(emailForm === '' ||   !validateEmail.test(emailForm) )
        passwordForm === '' && setpasswordFormError(true)
        passwordFormAgain === '' ? setpasswordFormError(true) : setpasswordFormError(false)
        passwordFormAgain !== passwordForm ? setpasswordFormError(true) : setpasswordFormError(false)

        setshowPasswordErrorArea(true)
        phoneForm.length < 4 ? setphoneFormError(true) : setphoneFormError(false)  
        !termsForm && settermsFormEror(true)
        unity === '' ? setUnityError(true) : setUnityError(false)
        checkEmailApi(emailFormError,'form')

       if (iscollaborator==='true') {          
            if (passwordForm.length>7 && nameForm!=='' && unity!=='' && executeRecaptcha && !nameFormError && emailFormError===false && city !== '' && phoneForm.length > 2 && termsForm && !passwordFormError && passwordFormAgain === passwordForm) {
                setLoadHidden(false)
                await sendData()
            }
        } else {

            if (passwordForm.length>7 && nameForm!=='' && executeRecaptcha && !nameFormError && emailFormError===false && city !== '' && phoneForm.length > 2 && termsForm && !passwordFormError && passwordFormAgain === passwordForm) {
                setLoadHidden(false)
                await sendData()
            }
        }

    }

    const sendData = async () => {

        if (iscollaborator === 'true') {
            const response = await fetch(`${home}/users/signUp`,
                {
                    method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8' }, body: JSON.stringify({
                        "city": city,
                        "email": emailForm,
                        "name": nameForm,
                        "state": state,
                        "phone": phoneForm,
                        "country": country,
                        "password": passwordForm,
                        "unit": unity
                    }),
                }),
                data = await response.json()
            data.status === 'Success' && setisFinished(true)
            if (data.status !== 'Success') {
                if (data.data.name && data.data.name === 'UsernameExistsException') {
                    setModalErrorText(1)
                    setShowModal(true)
                    setLoadHidden(true)
                    setemailFormError(true)
                } else {
                    setModalErrorText(2)
                    setShowModal(true)
                    setLoadHidden(true)
                }
            }
        } else {
            const response = await fetch(`${home}/users/signUp`,
                {
                    method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8' }, body: JSON.stringify({
                        "city": city,
                        "email": emailForm,
                        "name": nameForm,
                        "state": state,
                        "phone": phoneForm,
                        "country": country,
                        "password": passwordForm,
                    })
                }),
                data = await response.json()
            data.status === 'Success' && setisFinished(true)
            if (data.status !== 'Success') {
                if (data.data.name && data.data.name === 'UsernameExistsException') {
                    setModalErrorText(1)
                    setShowModal(true)
                    setLoadHidden(true)
                    setemailFormError(true)
                } else {
                    setModalErrorText(2)
                    setShowModal(true)
                    setLoadHidden(true)
                }
            }
        }
    }

    return (
        <form id="form">
            <ModalError showModal={showModal} setShowModal={setShowModal} modalErrorText={modalErrorText} />
            <div className="form-load" style={{ display: loadHidden ? 'none' : 'flex' }}>
                <Lottie options={loadDefaultOptions} height={'40vh'} width={'40vh'} />
            </div>
            <p className="form-text">{t("PLine01")}<br />{t("PLine02")}<span>Sipat NEXA</span></p>
            <TextField className="form-nameinput" label={t('nameInputLabel')} type="text"
                autoComplete="new-password"
                id="nome"
                onChange={handleChangeName}
                helperText={!nameFormError ? "" : t('nameInputHelper')}
            />
            <TextField className="form-emailinput" label={t('emailInputLabel')} type="email"
                autoComplete="new-password"
                id="email"
                onChange={handleChangeEmail}
                helperText={!emailFormError ? "" : emailFormErrorText} />
            <div className="form-local">
                <TextField
                    className="form-countryinput"
                    id="standard-select-currency"
                    select
                    label={t('countryInputLabel')}
                    value={country}
                    onChange={handleChangeCountry}
                    SelectProps={{ native: false }}
                    helperText={!countryFormError ? "" : t('nameInputHelper')}>
                    {countries.map((option) => (
                        <MenuItem key={option.value} value={option.value} >
                            {option.label}
                        </MenuItem >
                    ))}
                </TextField>
                <div className="form-estCid">
                    <TextField
                        className="form-cityinput"
                        id="estado "
                        select
                        label={t('stateInputLabel')} type="text"
                        value={state}
                        onChange={handleChangeState}
                        SelectProps={{ native: false }}
                        helperText={!stateFormError ? "" : t('nameInputHelper')}>
                        {states.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        className="form-cityinput"
                        id="cidade"
                        select
                        label={t('cityInputLabel')} type="text"
                        value={city}
                        onChange={handleChangeCity}
                        SelectProps={{ native: false }}
                        helperText={!cityFormError ? "" : t('nameInputHelper')}>
                        {
                            cities.map((option) => (<MenuItem key={option} value={option} >{option}</MenuItem>))
                        }
                    </TextField>
                </div>
            </div>
            <div className="form-passwordAreaError">
                <div className="form-passwordArea">
                    <TextField
                        className="form-passwordinput"
                        id="input-with-icon-textfield"
                        label={t('passwordInputLabel')} type={passwordhidden ? "password" : "text"}
                        autoComplete="new-password" onChange={e => setpasswordForm(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => { setpasswordhidden(!passwordhidden) }}>
                                        {passwordhidden ? <VisibilityIcon style={{ color: "#FF6505" }} /> : <VisibilityOffIcon style={{ color: "#FF6505" }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            maxLength: 16,
                            minLength: 8
                        }} />
                    <TextField className="form-passwordinput" label={t('passwordAgainInputLabel')} type={passwordhidden ? "password" : "text"}
                        autoComplete="new-password" onChange={e => setpasswordFormAgain(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => { setpasswordhidden(!passwordhidden) }}>
                                        {passwordhidden ? <VisibilityIcon style={{ color: "#FF6505" }} /> : <VisibilityOffIcon style={{ color: "#FF6505" }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        id="newpwd" inputProps={{
                            maxLength: 16,
                            minLength: 8
                        }} />
                </div>

                <div className="form-passworderror" style={!showPasswordErrorArea ? { height: 0, overflow: 'hidden' } : { height: 'auto', overflow: 'initial' }}>
                    <PasswordChecklist
                        className={!passwordFormError ? "form-passwordcheckhidden" : "form-passwordcheck"}
                        rules={["minLength", "match"]}
                        minLength={7}
                        value={passwordForm}
                        valueAgain={passwordFormAgain}
                        messages={{
                            minLength: t('passwordError8charaters'),
                            match: t('passwordErrorMatch'),
                        }}
                    />
                </div>
            </div>

            <div className="form-radiophoneArea"  >
                <div className="form-radio">
                    <label htmlFor="collab" style={{ color: "#FF6505" }} > {t('radioLabel')}?</label>
                    <RadioGroup
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            color: "#FF6505"
                        }}
                        id="collab"
                        defaultValue='true'
                        onChange={radioEvent}
                    >
                        <FormControlLabel
                            value='true'
                            control={
                                <Radio style={{ color: "#FF6505" }} />
                            }
                            label={t('radioTrueValue')}
                        />
                        <FormControlLabel
                            value='false'
                            control={
                                <Radio style={{ color: "#FF6505" }} />
                            }
                            label={t('radioFalseValue')}
                        />
                    </RadioGroup>
                </div>
                <MaterialUiPhoneNumber
                    className="form-phoneinput"
                    preferredCountries={['br', 'us', 'lu', 'pe']}
                    disableAreaCodes={true}
                    label={t('phoneInputLabel')} placeholder="(XX) 00000-0000"
                    defaultCountry={'br'}
                    onChange={handleChangePhone}
                    helperText={t(!phoneFormError ? '' : 'nameInputHelper')}
                />
            </div>
            <div className={`form-selectArea_${iscollaborator === 'false' ? 'true' : 'false'}`}>
                <label htmlFor="collab" className='form-label' style={{ color: "#FF6505", alignSelf: "flex-start" }} > {t('selectTitle')}</label>
                <TextField
                    id="standard-select-currency"
                    className="form-inputArea"
                    select
                    value={unity}

                    onChange={handleChangeUnity}
                    variant='outlined'
                    helperText={unityError ? t('unityHelper') : ''}
                >
                    {unities.map((option) => (
                        <MenuItem key={option.id} value={option.id} >
                            {option.name}
                        </MenuItem >
                    ))}
                </TextField>

            </div>
            <div className="form-termsError">
                <div className="form-terms">
                    <FormControlLabel
                        value='true'
                        control={
                            <Radio
                                style={{ color: "#FF6505" }}
                                checked={termsForm} onClick={handleTermsChange}
                            />
                        }
                    />
                    <p className="form-termsP">{t('agreeTermsPt1')}<span>{t('agreeTermsPt2')}</span>{t('e')}<span><a target='_blank' rel="noreferrer" href={`https://cdn.sipatnexa.com/policy/privacy-${language === 'br' ? 'pt' : language}.html`}> {t('agreeTermsPt3')}</a></span></p>
                </div>
                <p className="formsTermsHelper"
                    style={termsFormEror ? { display: 'initial' } : { display: 'none' }}
                >{t('termsHelper')}</p>
            </div>
            <div className="form-recaptchafinishbtnArea">
                <button className="form-btnfinish" onClick={handleSubmit}>
                    <h3 className="form-btnfinishtext">{t('ConfirmRegistration')}</h3>
                    <div className="form-btnimgWrapper">
                        <img src={BtnImg} className='form-btnimg' alt="" />
                    </div>
                </button>
            </div>
        </form>
    )
}

Form.propTypes = {
    language: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    setLanguage: PropTypes.func.isRequired,
    setIsCollaborator: PropTypes.func.isRequired,
    setUnity: PropTypes.func.isRequired,
    setPhone: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    setPasswordAgain: PropTypes.func.isRequired,
    setTerms: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setIsError: PropTypes.func.isRequired,
    setIsSuccess: PropTypes.func.isRequired,
    setIsErrorMessage: PropTypes.func.isRequired,
    setIsSuccessMessage: PropTypes.func.isRequired,
    setisFinished: PropTypes.func.isRequired,
}
export default Form