import React from 'react';
import './style.scss';
import WinnersPodiumCard from '../WinnersPodiumCard';
import ChangeLanguage from '../../Components/ChangeLanguage';
import nexaOrange from '../../img/logo-sipat-nexa-orange.png';
import logoZeroHarm from '../../img/logoZeroHarm.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import PropTypes from 'prop-types';

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });



const WinnersLeft = props => {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 }
    };

    const { t } = useTranslation()

    const { unity, setUnity, unities, rank, setRank, language, setLanguage } = props,
        home = process.env.REACT_APP_SIPAT_API


    const handleChangeUnity = async (event) => {
        const response = await fetch(`${home}/dashboard/ranking?unit=${event.target.value}`),
            data = await response.json()
        setRank(data.data.slice(0, 10))
        await setUnity(event.target.value)
       
    }

    return (
        <div className='winnersLeft'>
            <div className="winnersLeft-top">
                <div className="winnersLeft-Header">
                    <div className="winnersLeft-filterArea">
                        <h1 className="winnersLeft-title">{t('WinnersH1')}</h1>
                        <div className="winnersLeft-filter">
                            <p className="winnersLeft-selectP">{t('selectTitle')}</p>
                            <select className="winnersLeft-selectUnity" name="unities" id="unities" onChange={handleChangeUnity}>
                                {unities && unities.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="winnersLeft-logosArea">
                        <img src={nexaOrange} alt="Logo Sipat Nexa" className="winnersLeft-logoNexa" />
                        <img src={logoZeroHarm} alt="Logo Zero Harm" className="winnersLeft-logoZH  " />
                    </div>
                </div>
                <div className="winnersLeft-cardsArea">
                    <AliceCarousel
                    
                        mouseTracking
                        //items={items}
                        responsive={responsive}
                        //controlsStrategy="alternate"
                        disableDotsControls={true}
                        disableButtonsControls={true}
                    //infinite
                    >
                  

                        {
                            rank.slice(0, 3).map((option, index) => (
                                <WinnersPodiumCard key={index} postionRank={index + 1} nameRank={option.nickname} unityRank={unities[unity - 1] && unities[unity - 1].name} coinsRank={option.coins} avatarid={option.avatarid} />
                            ))}
                       

                    </AliceCarousel>
                </div>
            </div>
            <div className="winnersLeft-bottom">
                <ChangeLanguage language={language} setLanguage={setLanguage} />
            </div>
        </div>
    );
}

WinnersLeft.propTypes = {
    unity: PropTypes.number,
    setUnity: PropTypes.func,
    unities: PropTypes.array,
    rank: PropTypes.array,
    setRank: PropTypes.func,
    language: PropTypes.string,
    setLanguage: PropTypes.func
}

export default WinnersLeft;
