import React,{useEffect} from 'react';
import './style.scss'
import emailfound from '../../img/emailfound.svg'
import error500 from '../../img/error500.svg'
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next'
import pt_BR from '../../translations/pt_BR.json'
import en_US from '../../translations/en_US.json'
import es_ES from '../../translations/es_ES.json'
import PropTypes from 'prop-types';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });


const ModalError = props => {
    const { t } = useTranslation()
    const { showModal, setShowModal, modalErrorText,language } = props
    const handleClick = e => {
        e.preventDefault()
        setShowModal(false)

       
    }
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);
        const returnError = () => {
            if (modalErrorText === 1) {
                return <div className="modalError-FullText">
                    <h1 className={`modalError-title_${modalErrorText}`}>{t('Email encontrado')}</h1>
                    <p className='modalError-text'>{t('emailErrorP')}</p>
                </div>

            } else {
                return <div className="modalError-FullText">
                    <h1 className='modalError-title'>Oops!</h1>
                    <p className='modalError-text'>{t('ErrorModalGenP1')}</p>
                    <p className='modalError-text'>{t('ErrorModalGenP2')}</p>
                </div>

            }
        }
    return (
        <div className={`modalError_${showModal}`}>
            <div className={`modalError-modal_${showModal}`}>
                <img src={modalErrorText === 1 ? emailfound : error500} className='ModalErrorImg' alt="Icon Error" />
                {returnError()}
                <button className='modalError-btn' onClick={handleClick} style={{ display: !showModal && 'none' }}>
                    confirmar
                </button>
            </div>
        </div>

    );
}

ModalError.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    modalErrorText: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired
}

export default ModalError;
