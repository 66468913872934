import React, { useEffect, useContext } from 'react';
import NexaLogo from '../../img/logo-sipat-nexawt.png';
import ChangeLanguage from '../ChangeLanguage/index';
import './style.scss';
import i18n from "i18next";
import { useTranslation, initReactI18next } from 'react-i18next';
import pt_BR from '../../translations/pt_BR.json';
import en_US from '../../translations/en_US.json';
import es_ES from '../../translations/es_ES.json';
import DownloadBtnLeft from '../DownloadBtnLeft';
import { BrowserView, MobileView } from "react-device-detect";
import PropTypes from 'prop-types';
import sipatContext from '../../Context/Context';
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_US
            },
            br: {
                translation: pt_BR
            },
            es: {
                translation: es_ES
            }
        },
        lng: 'en',
        fallbackLng: ['en', 'br', 'es'],

        interpolation: {
            escapeValue: false
        }
    });

const BannerLeft = props => {
    const { eventdate, rewarddate } = useContext(sipatContext)
    const { t } = useTranslation()
    const { language, setlanguage } = props

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);


    const scrollPage = () => {
        window.scrollBy({ top: +(window.innerHeight + 200), behavior: "smooth" });
    }
    return (
        <div className={`bannerLeft ${props.isFinished}`}>
            <div className="bannerLeft-content">
                <p className="bannerLeft-dateP">{t("Evento dos dias ")}<br /> {`${eventdate.toString().split('-')[2]}`} {t(`since`)} {`${rewarddate.toString().split('-')[2]} ${t(`month`)} `}</p>
                <div className="bannerLeft-imgWrapper">
                    <img src={NexaLogo} alt="" />
                </div>
                <p className="bannerLeft-dateP2">{t("Evento dos dias ")}<br />{`${eventdate.toString().split('-')[2]}`} {t(`since`)} {`${rewarddate.toString().split('-')[2]} ${t(`month`)}`}</p>
                <div className="BannerLeft-downloadChangeLanguage">
                    <div className="BannerLeft-downloadBtns">
                        <BrowserView>
                            <DownloadBtnLeft typeBtn={'android'} />                            
                        </BrowserView>
                        <BrowserView>                          
                            <DownloadBtnLeft typeBtn={'ios'} />
                        </BrowserView>
                        <MobileView>
                            <DownloadBtnLeft typeBtn={'dynamic'} />
                        </MobileView>
                    </div>

                    <ChangeLanguage language={language} setLanguage={setlanguage} />
                </div>

                <p className="bannerLeft-textP">{t("PLine01")}<br />{t("PLine02")}<span>Sipat Nexa</span></p>
               

                <div className="bannerLeft-btnWrapper" onClick={scrollPage}></div>

            </div>
        </div>
    );
};


BannerLeft.propTypes = {
    isFinished: PropTypes.bool,
    language: PropTypes.string,
    setlanguage: PropTypes.func
}
export default BannerLeft;